<template>
  <div class="mb-4" v-for="media in mediaUrls" :key="media.id">
    <div
      class="flex lg:m-3 md:m-6 m-2 md:block-height block-mobile-height w-auto"
    >
      <div class="relative md:w-1/3 w-2/5">
        <img
          src="../../assets/image/dark-y-t-play.png"
          alt=""
          class="absolute top-0 bottom-0 right-0 left-0 m-auto w-12 cursor-pointer"
          @click="playFullscreen(media.id, media.public_id)"
        />
        <div
          :style="
            'background:url(https://img.youtube.com/vi/' +
            getUrlId(media.url) +
            '/mqdefault.jpg); background-position: center; background-size: cover;'
          "
          :class="{
            'rounded-l-3xl h-full w-full': playingVideo !== media.id,
            'fixed h-screen w-screen top-0 left-0 z-10':
              playingVideo === media.id,
          }"
        ></div>
        <iframe
          :id="media.id"
          :src="media.url"
          type="text/html"
          frameborder="0"
          :class="{
            'rounded-l-3xl h-full w-full hidden': playingVideo !== media.id,
            'fixed h-screen w-screen top-0 left-0 z-10':
              playingVideo === media.id,
          }"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture fullscreen;"
          allowfullscreen
        >
        </iframe>
        <div
          @click="closePlayer(media)"
          :class="{
            'rounded-full text-xl rapbg text-white z-10 px-2 py-1': true,
            hidden: playingVideo !== media.id,
            'fixed top-2 right-2': playingVideo === media.id,
          }"
        >
          Close
        </div>
        <div
          class="absolute inset-0"
          @click="playFullscreen(media, media.public_id)"
        ></div>
      </div>
      <div
        class="md:w-2/3 w-3/5 px-2 flex flex-col justify-center border-4 border-gray-400 rounded-r-3xl"
      >
        <div class="py-5">
          <p class="lg:text-sm md:text-2xl mb-3 block-text">
            {{ media.title }}
          </p>
          <p class="truncate xl:text-md lg:text-sm text-sm text-gray-400">
            {{ media.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../api/http";
import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { mapGetters } from "vuex";

export default {
  name: "VideoPlayer",
  components: {},
  emits: ["changePlays"],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      playingVideo: null,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({ user: "User/user" }),
    mediaUrls() {
      return this.$store.getters["Topsongs/mediaUrls"];
    },
  },
  methods: {
    init() {
      API.get("top-songs/").then((response) => {
        this.$store.commit("Topsongs/SET_TOP_SONGS", response.plays);
        // this.mediaUrls = response.plays;
      });
    },
    playFullscreen(media, songPublicId) {
      var id = media.id;
      API.post("increment", {
        user_public_id: this.user.public_id ? this.user.public_id : 0,
        artist_media_public_id: songPublicId,
      }).then((response) => {
        this.$emit("changePlays", response.plays);
      });

      var vid = document.getElementById(id);
      var videoSRC = media.url;
      if (videoSRC.indexOf("autoplay=1") === -1) {
        if (videoSRC.indexOf("?") === -1) {
          videoSRC +=
            "?autoplay=1&enablejsapi=1&version=3&playerapiid=ytplayer";
        } else {
          videoSRC +=
            "&autoplay=1&enablejsapi=1&version=3&playerapiid=ytplayer";
        }
      }
      vid.src = videoSRC;

      this.playingVideo = id;

      // https://www.youtube.com/embed/LrJbrydDf2s?enablejsapi=1&version=3&playerapiid=ytplayer

      // alert(vid.src);
      // if (~youtubevideo.src.indexOf("?")) youtubevideo.src += "&autoplay=1";
      // else youtubevideo.src += "?autoplay=1";

      // vid.style.position = "fixed";
      // vid.style.width = "100vw";
      // vid.style.height = "100vh";
      // vid.style.top = "0px";
      // vid.style.left = "0px";
      // document.getElementById("exit").style.display = "inline";
    },
    closePlayer(media) {
      var vid = document.getElementById(media.id);
      vid.src = media.url;
      this.playingVideo = null;
    },
    getUrlId(url) {
      var regExp =
        /^.*((youtube.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    },
  },
};
</script>
