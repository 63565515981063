<template>
  <div>
    <Loading v-if="loading" />
    <div v-if="!mediaUrls">
      <p class="text-center p-5">Media not found</p>
    </div>
    <div class="grid grid-cols-1 mx-4" v-else>
      <VideoPlayer></VideoPlayer>
    </div>
  </div>
</template>

<script>
import API from "../../api/http";
import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { mapGetters } from "vuex";
import Loading from "../Shared/Loading";
import VideoPlayer from "./VideoPlayer.vue";

export default {
  name: "TopSongs",
  components: {
    Loading,
    VideoPlayer,
  },
  emits: ["changePlays"],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      // mediaUrls: [],
      playingVideo: null,
      // loading: true,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({ user: "User/user" }),
    mediaUrls() {
      return this.$store.getters["Topsongs/mediaUrls"];
    },
    loading() {
      return this.$store.getters["Topsongs/loading"];
    },
  },
  methods: {
    init() {
      API.get("top-songs/").then((response) => {
        this.$store.commit("Topsongs/SET_TOP_SONGS", response.plays);
        this.$store.commit("Topsongs/SET_LOADING", false);
        // this.mediaUrls = response.plays;
        // this.loading = false;
      });
    },
    playFullscreen(media, songPublicId) {
      var id = media.id;
      API.post("increment", {
        user_public_id: this.user.public_id ? this.user.public_id : 0,
        artist_media_public_id: songPublicId,
      }).then((response) => {
        this.$emit("changePlays", response.plays);
      });

      var vid = document.getElementById(id);
      var videoSRC = media.url;
      if (videoSRC.indexOf("autoplay=1") === -1) {
        if (videoSRC.indexOf("?") === -1) {
          videoSRC +=
            "?autoplay=1&enablejsapi=1&version=3&playerapiid=ytplayer";
        } else {
          videoSRC +=
            "&autoplay=1&enablejsapi=1&version=3&playerapiid=ytplayer";
        }
      }
      vid.src = videoSRC;

      this.playingVideo = id;

      // https://www.youtube.com/embed/LrJbrydDf2s?enablejsapi=1&version=3&playerapiid=ytplayer

      // alert(vid.src);
      // if (~youtubevideo.src.indexOf("?")) youtubevideo.src += "&autoplay=1";
      // else youtubevideo.src += "?autoplay=1";

      // vid.style.position = "fixed";
      // vid.style.width = "100vw";
      // vid.style.height = "100vh";
      // vid.style.top = "0px";
      // vid.style.left = "0px";
      // document.getElementById("exit").style.display = "inline";
    },
    closePlayer(media) {
      var vid = document.getElementById(media.id);
      vid.src = media.url;
      this.playingVideo = null;
    },
    getUrlId(url) {
      var regExp =
        /^.*((youtube.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    },
  },
};
</script>
